.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #282c34;
  color: white;
  text-align: center;
}

.countdown-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.countdown-box {
  background-color: #333;
  padding: 20px;
  padding-bottom: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
}

.label {
  font-size: 1.2rem;
  color: #ffffff;
}

.completed {
  font-size: 2rem;
  font-weight: bold;
  color: #61dafb;
}

@media (max-width: 768px) {
  .countdown-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .countdown-box {
    padding: 15px;
  }

  .time {
    font-size: 1.5rem;
  }

  .label {
    font-size: 0.8rem;
  }

  img {
    width: 200px; /* Diminui a imagem em telas menores */
  }

  p {
    font-size: 1.5em; /* Diminui o título em telas menores */
  }
}

@media (max-width: 480px) {
  .time {
    font-size: 1.4rem;
  }

  .label {
    font-size: 0.9rem;
  }

  img {
    width: 150px;
  }

  p {
    font-size: 1em;
  }
}
